import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import Customer from './Customer';
import ScheduleDatetimeCell from './ScheduleDatetimeCell';
import FreeText from './FreeText';
import StatusCell from './StatusCell';
import Recycle from '../../Recycle';
import {makeStyles} from "@material-ui/core/styles";
import {sharedStyles} from '../../shared/styles';
import MetricCard from "../Metric";

const statuses_awaiting_acceptance = [
    {
        status: "order_confirmed",
        name: "Confirm"
    },
    {
        status: "delivered",
        name: "Delivered"
    },
    {
        status: "cancelled",
        name: "Cancelled"
    }
]
const statuses_order_confirmed = [
    {
        status: "delivered",
        name: "Delivered"
    },
    {
        status: "cancelled",
        name: "Cancelled"
    }
]

const initialStatuses = {
    awaiting_acceptance: 0,
    order_confirmed: 0,
    delivered: 0,
    cancelled: 0,
}


const OrdersTable = ({config, props, refresh}) => {
    const sharedClasses = sharedStyles();
    const tableRef = React.createRef();
    const [tableStatuses, setTableStatuses] = React.useState(initialStatuses);
    const [orderBy, setOrderBy] = React.useState(-1)
    const [orderDirection, setOrderDirection] = React.useState("")
    const [page, setPage] = React.useState(0)

    const columns = [
        {
            field: 'fullName',
            title: 'Customer',
            customFilterAndSearch: (term, rowData) => rowData.first_name.includes(term),
            customSort: (a, b) => a.first_name > b.first_name ? -1 : 1,
            render: (rowData) => <Customer appointment={rowData}/>
        },
        {
            field: 'facility',
            title: 'Facility',
            customFilterAndSearch: (term, rowData) => rowData.facility.includes(term),
            customSort: (a, b) => a.facility > b.facility ? -1 : 1,
            render: (rowData) => <FreeText service={rowData.facility}/>
        },
        {
            field: 'set',
            title: 'Set',
            customFilterAndSearch: (term, rowData) => rowData.order.includes(term),
            customSort: (a, b) => a.order > b.order ? -1 : 1,
            render: (rowData) => <FreeText service={rowData.order}/>
        },
        {
            field: 'timestamp',
            title: 'Ordered On',
            searchable: false,
            customSort: (a, b) => (new Date(a.timestamp)) > (new Date(b.timestamp)) ? -1 : 1,
            render: (rowData) => <ScheduleDatetimeCell datetime={rowData.timestamp}/>
        },
        {
            field: 'delivery_date',
            title: 'Procedure Date & Time',
            customFilterAndSearch: (term, rowData) => rowData.delivery_date.includes(term),
            customSort: (a, b) => a.delivery_date > b.delivery_date ? -1 : 1,
            render: (rowData) => <FreeText service={rowData.delivery_date}/>
        },
        {field: 'status', title: 'Status', render: (rowData) => <StatusCell status={rowData.status}/>},
        {
            field: 'actions',
            title: 'Actions',
            render: (rowData) => rowData.status === 'cancelled' || rowData.status === 'delivered' ? null :
                <ActionsButton order={rowData} status={rowData.status} refresh={refresh}/>
        }
    ]

    const getStatuses = () => {
        let statuses = {...initialStatuses};
        props.orders.forEach((order, index, array) => {
            statuses[order.status] = statuses[order.status] + 1
        });
        return statuses
    }

    React.useEffect(() => {
        setTableStatuses(getStatuses())
    }, [props.orders])

    React.useEffect(() => {
        if (!tableRef.current)
            return;

        tableRef.current.dataManager.setData(props.orders);
        tableRef.current.onChangeOrder(orderBy, orderDirection)
        tableRef.current.onChangePage(null, page)

        // NB: page, orderBy and orderDirection intentionally left out of dep array
    }, [props.orders, tableRef])

    const updateOrderBy = (newOrderBy, newOrderDirection) => {
        setOrderBy(newOrderBy);
        setOrderDirection(newOrderDirection);
    }

    return (
        <React.Fragment>
            <MetricCard props={{metrics: {statuses: tableStatuses}}}/>
            <MaterialTable
                tableRef={tableRef}
                data={[]}
                columns={columns}
                onOrderChange={updateOrderBy}
                onChangePage={(page, pageSize) => setPage(page)}
                title={<div className={sharedClasses.subheading}>{config.title || props.title || "Appointments"}</div>}
                options={{
                    headerStyle: {
                        color: '#999999',
                        fontSize: '11px',
                        fontWeight: 600,
                        lineHeight: '15px'
                    },
                    actionsColumnIndex: -1,
                    minBodyHeight: '100%',
                    searchFieldStyle: {marginRight: "48px"}
                }}
            />
        </React.Fragment>
    )
};

const ActionsButton = ({order, status, refresh}) => {

    const classes = useStyles();

    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleCancel = () => {
        handleClose();
        Recycle.rpc({
            model: "OrderController",
            method: "update_order_status",
            args: ["no_show", order.id]
        }).then(res => {
            refresh();
        });
    }

    const handleStatusChange = (status) => {
        handleClose();
        Recycle.rpc({model: "OrderController", method: "update_order_status", args: [status, order.id, order.user_id]}).then(res => {
            refresh();
        });
    }

    return (
        <React.Fragment>
            <IconButton onClick={handleClick} className={classes.actions}><MoreHorizIcon/></IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {
                    status !== "awaiting_acceptance" ? null :
                        statuses_awaiting_acceptance.map(field => {
                            return (
                                status === field ?
                                    null :
                                    <MenuItem onClick={() => handleStatusChange(field.status)}>{field.name}</MenuItem>
                            )
                        })
                }
                {
                    status !== "order_confirmed" ? null :
                        statuses_order_confirmed.map(field => {
                            return (
                                status === field ?
                                    null :
                                    <MenuItem onClick={() => handleStatusChange(field.status)}>{field.name}</MenuItem>
                            )
                        })
                }
            </Menu>
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    actions: {
        padding: 0
    },
    s: {
        marginRight: "24px"
    }
}));

export default OrdersTable;