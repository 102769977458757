export const appointmentStatusColorMapping = (theme) => ({
    awaiting_acceptance: theme.palette.warning,
    order_confirmed: theme.palette.info_medium,
    delivered: theme.palette.success,
    cancelled: theme.palette.error,
    default: theme.palette.warning,
});

export const consultStatusColorMapping = (theme) => ({
    awaiting_acceptance: theme.palette.warning,
    order_confirmed: theme.palette.info_medium,
    delivered: theme.palette.success,
    cancelled: theme.palette.error,
    default: theme.palette.warning,
});

export const procedureIconMapping = {
};

export const procedureStatusColorMapping = (theme) => ({
    pending: theme.palette.warning,
    completed: theme.palette.success
});