import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { AppConfigContext } from '../App';
import Recycle from '../Recycle';

const Header = () => {
    const appConfig = useContext(AppConfigContext);
    const classes = useStyles();

    const handleLogout = () => {
        Recycle.logout();
        appConfig.setState({ ...appConfig, authenticated: false, initialized: false, user: null });
    }

    return (
        <AppBar className={classes.root} position="static">
            <Toolbar style={{ backgroundColor: 'white' }}>
                <div className={classes.logoContainer}>
                    <img className={classes.logo} src="/logo.png" alt="logo" />
                </div>
                <div className={classes.rightContainer}>

                    {appConfig.user ? <ProfileButton user={appConfig.user} onLogout={handleLogout} /> : null}
                </div>
            </Toolbar>
        </AppBar>
    )
};

const ProfileButton = ({ user, onLogout }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const showMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const logout = () => {
        handleClose();
        onLogout();
    }

    return (
        <React.Fragment>
            <ButtonBase className={classes.profileButtonContainer} onClick={showMenu}>
                <AccountCircleIcon color="primary" />
                <div className={classes.profile}>
                    <div className={classes.fullName}>{user.name}</div>
                    {/*<div className={classes.role}>{user.role.toUpperCase().replace(/_/g, ' ')}</div>*/}
                    <div className={classes.role}>Admin</div>
                </div>
                <ArrowDropDownIcon color="primary" />
            </ButtonBase>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        </React.Fragment>
    )
};

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none'
    },
    logoContainer: {
        flex: 1
    },
    logo: {
        height: '40px'
    },
    rightContainer: {
        display: 'flex',
    },
    profileButtonContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    profile: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        textTransform: 'capitalize',
        textAlign: 'left'
    },
    role: {
        color: '#999999',
        fontSize: '11px'
    },
    fullName: {
        color: theme.palette.primary.main,
        fontSize: '12px'
    },
    divider: {
        height: '24px',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    bellContainer: {
        marginRight: theme.spacing(3)
    }
}));

export default Header;