import React from 'react';
import dayjs from "dayjs";
import { makeStyles } from '@material-ui/core/styles';

const ScheduleDatetimeCell = ({ datetime }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <span className={classes.day}>{dayjs(datetime).format('ddd HH:mm')}, </span>
            <span>{dayjs(datetime).format('DD/MM/YYYY')}</span>
        </div>
    )
}

const useStyles = makeStyles({
    root: {
        fontSize: '12px',
        lineHeight: '17px'
    },
    day: {
        fontWeight: 600
    }
});

export default ScheduleDatetimeCell;