import React, { useState, useEffect, createContext } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import Login from './Login';
import Recycle from './Recycle';
import Base from './Base';
import AppTheme from './theme';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { SnackbarProvider, useSnackbar } from 'notistack';

const theme = createMuiTheme(AppTheme);

export const AppConfigContext = createContext({});

const App = () => {
    const [appConfig, setAppConfig] = useState({
        setState: (state) => {
            setAppConfig(state);
        },
        authenticated: false,
        pages: [],
        menu: [],
        user: null,
        rpc_loading: false,
        initialized: false,
        pendingRpcCalls: 0
    });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        Recycle.onRpcError((err) => {
            if (err.response.status === 401) {
                Recycle.logout();
                setAppConfig({ ...appConfig, authenticated: false, initialized: false, user: null });
            }
        });

        Recycle.pendingRpcCalls.subscribe((count) => {
            setAppConfig((oldConfig) => {
                return { ...oldConfig, pendingRpcCalls: count }
            });
        });

        if (Recycle.isAuthenticated()) {
            setAppConfig({ ...appConfig, authenticated: true });
        }
    }, []);


    const classes = useStyles();

    useEffect(() => {
        if (appConfig.authenticated) {
            const accessToken = Recycle.authProvider.getAccessToken();
            const idToken = Recycle.authProvider.getIdToken();
            const user = Recycle.authProvider.getUser();

            try {
                if (user.groups.indexOf(process.env.REACT_APP_USER_GROUP) < 0) {
                    throw Error();
                }

                var [role, dsp] = user['roles'][0].split(':');
            }
            catch (err) {
                Recycle.logout();
                enqueueSnackbar("User does not have any roles", { variant: "error" });
                setAppConfig({ ...appConfig, authenticated: false });
                return;
            }

            user.role = role;
            user.dsp = dsp;
            Recycle.setAuth('Authorization', accessToken);
            Recycle.setHeader('Identity', idToken);
            Recycle.fetchAppConfig().then(config => { setAppConfig({ ...appConfig, ...config, user, initialized: true }) });
        }
    }, [appConfig.authenticated]);

    const renderApp = () => {
        if (appConfig.authenticated && appConfig.initialized) {
            return <Base pages={appConfig.pages} menu={appConfig.menu} />
        }
        else if (!appConfig.authenticated) { return <Login /> }
    }

    return (
        <AppConfigContext.Provider value={appConfig}>
            <>
                <Backdrop className={classes.backdrop} open={appConfig.pendingRpcCalls > 0}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {renderApp()}
            </>
        </AppConfigContext.Provider>
    )
}

const RootApp = () => {
    return (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
                    <App />
                </SnackbarProvider>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    )
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#cbc5c5',
        backdropFilter: 'blur(5px)'

    },
}));


export default RootApp;