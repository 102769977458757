import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { appointmentStatusColorMapping } from '../const';

const Customer = ({ appointment }) => {
    const classes = useStyles(appointment);

    return (
        <div className={classes.root}>
            <div className={classes.statusRectangle}></div>
            <div>
                <div className={classes.fullName}>{`${appointment.first_name} ${appointment.last_name}`}</div>
                <div className={classes.contactNumber}>{appointment.user_id}</div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    statusRectangle: (props) => {
        theme.palette.info_medium = {
            light: "#64b5f6",
            main: "#509fde",
            dark: "#4792c6",
            contrastText: "#fff"
        }
        const backgroundColor = appointmentStatusColorMapping(theme)[props.status ? props.status : 'default'].main;

        return {
            width: '4px',
            height: '24px',
            position: 'absolute',
            left: 0,
            backgroundColor: backgroundColor
        }
    },
    fullName: {
        fontSize: '15px',
        fontWeight: 600
    },
    contactNumber: {
        fontSize: '14px',
        color: '#666666',
        lineHeight: '15px'
    }
}));

export default Customer;