import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';

const SideNavBar = ({ menu, className }) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const isActivePath = (path) => {
        const basePath = location.pathname.split('/')[1];
        return `/${basePath}` === path;
    }

    const renderNavigations = () => {
        return menu.map((item) => {
            const activePath = isActivePath(item.to);
            const classNames = clsx({ [classes.inactive]: true, [classes.active]: activePath });

            return (
                <ListItem
                    key={item.id}
                    onClick={() => history.push(item.to)}
                    button
                >
                    {activePath ? <div className={classes.activeBox} /> : null}
                    <ListItemIcon className={classNames}><Icon>{item.icon}</Icon></ListItemIcon>
                    <ListItemText className={classNames} primary={item.name} />
                </ListItem>
            )
        });
    }

    return (
        <div className={className}>
            <div className={classes.root}>
                <List component="nav">
                    {renderNavigations()}
                </List>
            </div>
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    root: {

    },
    activeBox: {
        height: '30px',
        width: '5px',
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        left: 0,
        borderRadius: '0 3px 3px 0'
    },
    inactive: {
        color: '#666666'
    },
    active: {
        color: theme.palette.primary.main
    }
}));

export default SideNavBar;