import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {appointmentStatusColorMapping} from '../const';

const StatusCell = ({status}) => {
    const classes = useStyles({status})

    return (
        <div className={classes.root}>
            {status ? status.replace(/_/g, ' ') : null}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: (props) => {
        theme.palette.info_medium = {
            light: "#64b5f6",
            main: "#509fde",
            dark: "#4792c6",
            contrastText: "#fff"
        }
        const color = appointmentStatusColorMapping(theme)[props.status ? props.status : 'default'].dark;
        return {
            color: color,
            textTransform: 'capitalize',
            fontSize: '12px'
        }
    }
}));

export default StatusCell;