"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidTelephoneNumber = exports.isValidSAIDNumber = exports.generateLuhnDigit = void 0;
var SAID_REGEX = new RegExp("^(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))$");
var E164_SA_NUMBER = new RegExp("^\\+27[0-9]{9}$");
var E164_SA_NUMBER_NO_PLUS = new RegExp("^27[0-9]{9}$");
var SA_NUMBER = new RegExp("^0[0-9]{9}$");
function generateLuhnDigit(inputString) {
    var total = 0;
    var count = 0;
    for (var i = 0; i < inputString.length; i++) {
        var multiple = count % 2 + 1;
        count++;
        var temp = multiple * +inputString[i];
        temp = Math.floor(temp / 10) + (temp % 10);
        total += temp;
    }
    total = (total * 9) % 10;
    return total;
}
exports.generateLuhnDigit = generateLuhnDigit;
;
function isValidSAIDNumber(idNumber) {
    if (!SAID_REGEX.test(idNumber))
        return false;
    var number = idNumber.substring(0, idNumber.length - 1);
    return generateLuhnDigit(number) === +idNumber[idNumber.length - 1];
}
exports.isValidSAIDNumber = isValidSAIDNumber;
exports.isValidTelephoneNumber = function (input, countryCode) {
    if (countryCode === void 0) { countryCode = 'ZA'; }
    try {
        return (SA_NUMBER.test(input) || E164_SA_NUMBER.test(input) || E164_SA_NUMBER_NO_PLUS.test(input));
    }
    catch (err) {
    }
    return false;
};
