"use strict";
// Aim: To simulate template string like functionality at runtime from static string sources.
//      eg: convert "/user/${user_id}" to "/user/1" given {user_id: 1}
Object.defineProperty(exports, "__esModule", { value: true });
exports.getObjectByPath = exports.interpolate = void 0;
// Source: https://stackoverflow.com/a/47358102
var regex = /\${[^{]+}/g;
//get the specified property or nested property of an object
function getObjPath(path, obj, fallback) {
    if (fallback === void 0) { fallback = ''; }
    return path.split('.').reduce(function (res, key) { return res[key] || fallback; }, obj);
}
function interpolate(template, variables, fallback) {
    return template.replace(regex, function (match) {
        var path = match.slice(2, -1).trim();
        return getObjPath(path, variables, fallback);
    });
}
exports.interpolate = interpolate;
exports.getObjectByPath = getObjPath;
