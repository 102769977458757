"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluatePolishExpression = void 0;
var operator_1 = require("./operators/operator");
var isOperator = function (operator) {
    return operator.op && operator.op !== 'null';
};
exports.evaluatePolishExpression = function (tokens, data) {
    if (data === void 0) { data = {}; }
    // @ts-ignore
    var _tokens = tokens.flat(0);
    var maxIterations = 100;
    var stack = [];
    while (_tokens.length > 0 || stack.length > 1) {
        var numOperands = 0;
        var expressionEvaluated = false;
        for (var _i = 0, _a = stack.slice().reverse(); _i < _a.length; _i++) {
            var token = _a[_i];
            var operator = operator_1.Operators.find(token);
            if (isOperator(operator)) {
                if (operator.numArgs === numOperands) {
                    var args = stack.splice(-operator.numArgs);
                    var op = stack.splice(-1); // We already know the op, so just remove it from the stack
                    if (operator === null || operator === void 0 ? void 0 : operator.injectFields) {
                        args.push(data);
                    }
                    expressionEvaluated = true;
                    stack.push(operator.evaluate.apply(operator, args));
                }
                break;
            }
            else {
                numOperands++;
            }
        }
        if (!expressionEvaluated) {
            var token = _tokens.shift();
            if (token !== undefined)
                stack.push(token);
        }
        if (--maxIterations === 0) {
            throw 'Max iterations exceeded while evaluating Polish Expression';
        }
    }
    return stack.shift();
};
