import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import MetricCard from "./MetricCard";

const MetricGroup = ({ config, props }) => {
    const classes = useStyles();

    let cards = [];
    for (let status in props.metrics.statuses) {
        cards.push({ status: status, count: props.metrics.statuses[status] })
    }
    return (
        <div className={classes.container}>
            {
                cards.map(card => {
                    return (
                        <MetricCard key={card.status} count={card.count} status={card.status} type={props.metrics.type} />
                    )
                })
            }
        </div>
    )

}

const useStyles = makeStyles((theme) => ({
    container: {
        display: "grid",
        gridAutoFlow: "column",
        gap: `${theme.spacing(3)}px`,
        paddingBottom: '20px'
    }
}));

export default MetricGroup;